export default defineStore('AlertBoxStore', {
    state: () => ({
        content: {
            text: '',
            isError: false,
        },
        isOpenValue: false,
    }),

    actions: {
        toggleAlertbox(forceClose = false) {
            this.isOpenValue = forceClose ? false : !this.isOpenValue;

            window.setTimeout(() => {
                this.isOpenValue = false;
            }, 6500);
        },

        setAlertBoxData(type, data) {
            const settings = { ...data };
            const typeIsError = type === 'error';

            this.content.text = typeIsError ? settings.error_text : settings.success_text;
            this.content.isError = typeIsError;
        },
    },

    getters: {
        isOpen(state) {
            return state.isOpenValue;
        },
        alertBoxContent(state) {
            return state.content;
        },
    },
});
